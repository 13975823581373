import React from "react"
import Link from "gatsby-link"

import Container from "../components/container"
import Title from "../components/hero/title"
import Subtitle from "../components/hero/subtitle"
import { space } from "../utils/presets"
import { linkStyles } from "../utils/styles"

const NotFoundPage = () => (
  <div css={{ margin: `10vw`, "& a": { ...linkStyles } }}>
    <Container>
      <Title>Page not found</Title>
      <Subtitle
        customStyles={{
          marginBottom: space[9],
          maxWidth: 400,
        }}
      >
        <em>Sorry</em> 😔—we couldn’t find what you were looking for.
      </Subtitle>
      <p>
        <strong>Need help finding something?</strong>
        <br />
        Email support@gatsbyjs.com and we’ll help you.
      </p>
      <Link to="/">gatsbyjs.com Homepage</Link>
    </Container>
  </div>
)

export default NotFoundPage
